import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col, Dropdown } from 'react-bootstrap';
import AdvancedSearchBackButton from './AdvancedSearchBackButton';

var User = null;
var Propslist = null;
var OrgList = [];
var OrgTable = [];

class UserResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedUser: false,
            loadedProps: false,
            org: 'Unknown'
        };

        if(AdvSch.Org) {
            this.state.org = AdvSch.Org.id;
        }
    }

    OrgRole(role) {
        switch (role) {
            case 4:
                return ("Owner");
            case 3:
                return ("Admin");
            case 2:
                return ("Associate");
            case 1:
                return ("Member");
            default:
                return ("None");
        }

    }

    makeDropdown(){
        <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item></Dropdown.Item>
        <Dropdown.Item></Dropdown.Item>
        <Dropdown.Item></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    }
    //get information based on filled out fields
    getUser() {
        if (this.state.loadedUser) {
            return User;
        }
        else {
            //Get all Users
            //console.log(AdvSch.User.id)
            let url = `${config.api}user/${AdvSch.User.id}`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = response["data"];
                    User = []

                    User.push(
                        <tbody>
                            <tr key={data.id}>
                                <th>Name:</th>
                                <td>{data.firstName} {data.lastName}</td>
                            </tr>

                            <tr>
                                <th>Email:</th>
                                <td>{data.email}</td>
                            </tr>
                        </tbody>
                    );

                    this.setState({
                        loadedUser: true,
                    });
                    // This is invoked here to avoid trying to populate the organizations before the user info has loaded
                    this.getOrgs(data);
                    return User;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }

    // Get the names of all of the organizations this user is a part of, and their role in it
    getOrgs(data) {
        console.log(data);
        let url = `${config.api}organization/`;
        console.log(url);
        let token = localStorage.getItem("mlToken");
        data.org.map((orgElement) => {
            axios.get(`${url}${orgElement.organizationID}`, {headers: { Authorization: `Bearer ${token}` }}).then((response) => {
                OrgList.push({label: response.data.label, role: orgElement.organizationRoleID});
                if(OrgList.length === data.org.length) {
                    this.BuildOrgTable();
                }
            })
        });
    }

    BuildOrgTable() {
        console.log(OrgList);
        OrgTable = (<tbody>
            {OrgList.map((data, index) => (
                <tr key={index}>
                    <th>Name:</th>
                    <td>{data.label}</td>
                    <th>Role:</th>
                    <td>{this.OrgRole(data.role)}</td>
                </tr>
            ))}
        </tbody>)
    }

    getProps() {
        if (this.state.loadedProps) {
            return Propslist;
        }
        else {
            //Get all Orgs
            let url = `${config.api}user/${AdvSch.User.id}/properties`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    ////console.log(response)
                    const data = response["data"];
                    //console.log(data)
                    Propslist = []
                    data.map((item) => {
                        Propslist.push(
                            <tr key={item.id}>
                                <td>{item.label}</td>
                            </tr>);
                        return item;
                    });
                    this.setState({
                        loadedProps: true,
                    });
                    return Propslist;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }


    render() {
        Swal.close();
        return (<Container>
            <AdvancedSearchBackButton />
            <Row>
                <Row>
                    <h2>User:</h2>
                    <Table>
                        {this.getUser()}
                    </Table>
                </Row>
                <Row>
                    <h2>Organizations:</h2>
                    <Table>
                        {OrgTable}
                    </Table>
                </Row>
                <Row>
                    <h2>Properties:</h2>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getProps()}
                        </tbody>
                    </Table>
                </Row>
            </Row>
        </Container>)
    }

}
export default withRouter(UserResultsView);
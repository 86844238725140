import React, { Component } from 'react';
import './TabViewTest.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';

class TabViewTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentDidMount(){
        document.body.style.backgroundColor = "#EBF2F8";
    }    

    render() {
        return (
            <div id="tabViewContainer" className="tabViewContainer">

                <Sidebar 
                    pageWrapId={"tabViewPageWrap"} 
                    outerContainerId={"tabViewContainer"} 
                    generateSummary={null}
                    buildings={null} 
                    buttonsVisible={false}
                />

                <Container id="tabViewPageWrap" className="tabViewPageWrap">
                    <Row className="tabViewRow">
                        <div className="tabViewBlock">

                            {/* Tab Example Code */}
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                                <Tab eventKey="home" title="Home">
                                    Example text 1
                                </Tab>
                                <Tab eventKey="profile" title="Profile">
                                    Example text 2
                                </Tab>
                                <Tab eventKey="contact" title="Contact">
                                    You can really put anything here and it won't show up.
                                    <br />
                                    <Button variant="primary">Where am I?</Button>
                                    <br />
                                    <h2>I'm not actually here</h2>
                                </Tab>
                            </Tabs>
                            
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default TabViewTest;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table,Row, Col } from 'react-bootstrap';
import AdvancedSearchBackButton from './AdvancedSearchBackButton'

var Userlist = null;
var Propslist = null;

class OrgResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedUsers: false,
            loadedProps: false,
        };
    }

    OrgRole(role) {
        switch (role) {
            case 4:
                return ("Owner");
            case 3:
                return ("Admin");
            case 2:
                return ("Associate");
            case 1:
                return ("Member");
            default:
                return ("None");
        }

    }

//get information based on filled out fields
    getUsers() {
        if (this.state.loadedUsers) {
            return Userlist;
        }
        else {
            //Get all Users
            let url = `${config.api}users/organization/${AdvSch.Org.id}`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = response["data"];
                    Userlist = []
                    data.map((item) => {
                        Userlist.push(
                            <tr key={item.id}>
                                <td>{item.firstName} {item.lastName}</td>
                                <td>{item.email}</td>
                                <td>{this.OrgRole(item.organizationRoleID)}</td>
                            </tr>);
                        return item;
                    });
                    this.setState({
                        loadedUsers: true,
                    });
                    return Userlist;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }



    getProps() {
        if (this.state.loadedProps) {
            return Propslist;
        }
        else {
            //Get all Orgs
            let url = `${config.api}organization/${AdvSch.Org.id}/properties`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = response["data"];
                    Propslist = []
                    data.map((item) => {
                        Propslist.push(
                            <tr key={item.id}>
                                <td>{item.label}</td>
                            </tr>);
                        return item;
                    });
                    this.setState({
                        loadedProps: true,
                    });
                    return Propslist;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }


    render() {
            Swal.close();
            return (<Container>
                <AdvancedSearchBackButton />
                <h1>Organization: {AdvSch.Org.name}</h1>
                <Row>
                <h2>Users:</h2>
                <Table>
                    <thead>
                        <tr>
                            <th>Name:</th>
                            <th>Email:</th>
                            <th>Role:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getUsers()}
                    </tbody>
                </Table>
                </Row>
                <Row>
                <h2>Properties:</h2>
                <Table>
                    <thead>
                        <tr>
                            <th>Name:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.getProps()}
                    </tbody>
                </Table>
                </Row>
            </Container>)
    }

}
export default withRouter(OrgResultsView);
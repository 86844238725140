import React from 'react';
import { withRouter } from 'react-router';
import config from '../../../utilities/config';

import './TopUsingRooms.scss';
import '../DashboardBlocks/GeneralBlocks.scss';
import TopUsingRoomBlock from './TopUsingRoomBlock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { CSVLink } from 'react-csv';

const TopUsingRooms = (props) => {
    const buildings = props.buildings;
    //console.log(buildings);
    const leakData = props.leakData;
    const dataKeys = Object.keys(leakData);
    let numProblems = 0;

    //Recreate the leakData Object with only the room labels and leak totals
    let roomUsage = {};

    for (let i = 0; i < dataKeys.length; i++) {
        const problems = leakData[dataKeys[i]];
        const problemKeys = Object.keys(leakData[dataKeys[i]]);
        roomUsage[dataKeys[i]] = {};
        for (let k = 0; k < problemKeys.length; k++) {
            const curRoom = problems[problemKeys[k]]["locationLabel"]
            //console.log(curRoom);
            //console.log(problemKeys);
            //console.log(problems);
            if (roomUsage[dataKeys[i]][curRoom] == null) {
                roomUsage[dataKeys[i]][curRoom] = problems[problemKeys[k]]["gallons"]
            }
            else {
                roomUsage[dataKeys[i]][curRoom] += problems[problemKeys[k]]["gallons"]
            }
        }
    }

    //Recreate the roomUsage object with the list of rooms/totals as an array
    let roomUsageToDisplay = {};

    for (let i = 0; i < dataKeys.length; i++) {
        const problems = roomUsage[dataKeys[i]];
        const problemKeys = Object.keys(roomUsage[dataKeys[i]]);
        roomUsageToDisplay[dataKeys[i]] = [];
        let buildingTitle = "";
        buildings.forEach((building) => {
            if (building.id == dataKeys[i]) {
                buildingTitle = building.label;
            }
        })
        for (let k = 0; k < problemKeys.length; k++) {
            const curRoom = problemKeys[k]
            const curTotal = problems[problemKeys[k]]
            const curUsage = { "building": buildingTitle, "buildingID": dataKeys[i], "room": curRoom, "total": curTotal };
            roomUsageToDisplay[dataKeys[i]].push(curUsage);
        }
    }

    //Create the table rows for the room totals
    let usageBlocks = [];
    let problems = [];
    var topUsingExportable = [["Building", "Location", "Gallons Wasted"]];
    for (let i = 0; i < dataKeys.length; i++) {
        problems = problems.concat(roomUsageToDisplay[dataKeys[i]]);
        problems.sort((a, b) => (a.total < b.total) ? 1 : -1)
    }

    if (problems.length > 0) {
        numProblems += 1;
        usageBlocks = problems.map((problem) => {
            topUsingExportable.push([problem.building, problem.room, problem.total]);
            // const total = Number(problem.total).toLocaleString();
            return (
                <tr onClick={() => {
                    props.history.push(`/room-view?roomName=${problem.room}`)
                    config.selectedBuilding = problem.buildingID;
                    localStorage.setItem("mlBuildingID", problem.buildingID);
                }}
                    style={{ cursor: 'pointer' }}
                >
                    <td>{problem.building}</td>
                    <td>{problem.room}</td>
                    <td>{problem.total.toLocaleString()}</td>
                </tr>
            )
        })
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <p className="blocksLabel">Wasted Water By Location</p>
                </Col>
            </Row>
            <Row className='topUsingRow'>
                {numProblems === 0 ?
                    <Col xs={12} className="d-flex">
                        <div className="colPlaceholder">No leaks reported</div>
                    </Col>
                    :
                    <Table striped responsive className='topUsingTable' >
                        <thead>
                            <tr>
                                <th>Building</th>
                                <th>Location</th>
                                <th>Gallons</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usageBlocks}
                        </tbody>
                    </Table>
                }
            </Row>
        </Container>
    );
};

export default withRouter(TopUsingRooms);
import React, { useState }  from 'react';
import { withRouter } from 'react-router';
import config from '../../../utilities/config';
// import './TopUsingRooms.scss';
// import '../DashboardBlocks/GeneralBlocks.scss';
// import TopUsingRoomBlock from './TopUsingRoomBlock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const PredictionBlocks = (props) => {


    let data = props.data;
    console.log("PROP DATA")
    console.log(data)

    let waterPrediction = []
    let energyPrediction = []
    let carbonPrediction = []


    if(data != null && data.length > 0 ){
        console.log("DATA LENGTH")
        console.log(data.length)
        for(let i = 0; i < data.length; i++){

            let total = data[i]["total"]
            let buildingLabel = total["buildingLabel"]
            let locationLabel = total["locationLabel"]
            let propertyLabel = total["propertyLabel"]
            let aDate = total["aDate"]

            console.log("TOTAL")
            console.log(total)
            console.log("LABELS")
            console.log(buildingLabel)
            console.log(locationLabel)

            let summary = data[i]["summary"]
            let carbon = summary["carbon"]
            let energy = summary["energy"]
            let water = summary["water"]


            let w30 = parseInt(water["30Days"])
            let w90 = parseInt(water["90Days"])
            let w180 = parseInt(water["180Days"])
            let w270 = parseInt(water["270Days"])
            let w365 = parseInt(water["365Days"])

            let w30Str = w30.toLocaleString();
            let w90Str = w90.toLocaleString();
            let w180Str = w180.toLocaleString();
            let w270Str = w270.toLocaleString();
            let w365Str = w365.toLocaleString();


            let c30 = parseFloat(carbon["30Days"].toFixed(4));
            let c90 = parseFloat(carbon["90Days"].toFixed(4));
            let c180 = parseFloat(carbon["180Days"].toFixed(4));
            let c270 = parseFloat(carbon["270Days"].toFixed(4));
            let c365 = parseFloat(carbon["365Days"].toFixed(4));

            let c30Str = c30.toLocaleString();
            let c90Str = c90.toLocaleString();
            let c180Str = c180.toLocaleString();
            let c270Str = c270.toLocaleString();
            let c365Str = c365.toLocaleString();

            let e30 = parseFloat(energy["30Days"].toFixed(2));
            let e90 = parseFloat(energy["90Days"].toFixed(2));
            let e180 = parseFloat(energy["180Days"].toFixed(2));
            let e270 = parseFloat(energy["270Days"].toFixed(2));
            let e365 = parseFloat(energy["365Days"].toFixed(2));

            let e30Str = e30.toLocaleString();
            let e90Str = e90.toLocaleString();
            let e180Str = e180.toLocaleString();
            let e270Str = e270.toLocaleString();
            let e365Str = e365.toLocaleString();


            
            waterPrediction.push(<tr><td>{aDate}</td><td>{propertyLabel}</td><td>{buildingLabel}</td><td>{locationLabel}</td><td>{w30Str}</td><td>{w90Str}</td><td>{w270Str}</td><td>{w365Str}</td></tr>)
            carbonPrediction.push(<tr><td>{aDate}</td><td>{propertyLabel}</td><td>{buildingLabel}</td><td>{locationLabel}</td><td>{c30Str}</td><td>{c90Str}</td><td>{c270Str}</td><td>{c365Str}</td></tr>)
            energyPrediction.push(<tr><td>{aDate}</td><td>{propertyLabel}</td><td>{buildingLabel}</td><td>{locationLabel}</td><td>{e30Str}</td><td>{e90Str}</td><td>{e270Str}</td><td>{e365Str}</td></tr>)
        
        

        }
        

    }

    else{
        waterPrediction = <tr></tr>
        energyPrediction = <tr></tr>
        carbonPrediction = <tr></tr>
    }
    





    console.log("IN PREDICTION BLOCKS")


    
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <p className="blocksLabel">Predicted Waste By Location</p>
                </Col>
            </Row>
            <Row>
            <Tabs
                                        defaultActiveKey="predictions/gallons"
                                        style={{ fontSize: 'small' }}
                                    >
                <Tab style={{ height: '90%' }} eventKey="predictions/gallons" title="Water (Gallons)">
                    <Table striped responsive >
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Property</th>
                                <th>Building</th>
                                <th>Location</th>
                                <th>1 Month</th>
                                <th>3 Months</th>
                                <th>6 Months</th>
                                <th>12 Months</th>

                            </tr>
                        </thead>
                        <tbody>
                            {waterPrediction}
                        </tbody>
                    </Table>
                </Tab>

                <Tab style={{ height: '90%' }} eventKey="predictions/carbon" title="Carbon (Tons)">
                <Table striped responsive >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Property</th>
                            <th>Building</th>
                            <th>Location</th>
                            <th>1 Month</th>
                            <th>3 Months</th>
                            <th>6 Months</th>
                            <th>12 Months</th>

                        </tr>
                    </thead>
                    <tbody>
                        {carbonPrediction}
                    </tbody>
                </Table>
                </Tab>
                <Tab style={{ height: '90%' }} eventKey="predictions/energy" title="Energy (kWh)">
                    <Table striped responsive >
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Property</th>
                                <th>Building</th>
                                <th>Location</th>
                                <th>1 Month</th>
                                <th>3 Months</th>
                                <th>6 Months</th>
                                <th>12 Months</th>

                            </tr>
                        </thead>
                        <tbody>
                            {energyPrediction}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>

            </Row>

        </Container>
    );
};

export default withRouter(PredictionBlocks);
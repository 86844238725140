import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col, Dropdown } from 'react-bootstrap';
import LoadingView from '../../LoadingView/LoadingView.js';
import AdvancedSearchBackButton from './AdvancedSearchBackButton';

var info = null;
var orgname = null;
var locaionList=null;

class BuildingResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedBuilding: false,
            loadedProp: false,
            loadedOrg: false,
            loadedLocs: false,
        };
    }
    componentDidMount() {
        this.getInfo()
    }
    //get information based on filled out fields
    getInfo() {
        info = []
        info.push(<tr key={AdvSch.Building.id}>
            <td>{"Building: " + AdvSch.Building.name}</td>
        </tr>);
        //Get Info
        let url = `${config.api}property/${AdvSch.Building.propID}`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                this.setState({
                    loadedProp: true,
                });
                //console.log("stuff", response)
                const data = response["data"];
                orgname = data.organizationID
                info.push(
                    <tr key={data.id}>
                        <td>{"Property: " + data.label}</td>
                    </tr>);
                    url = `${config.api}organization/${orgname}`;
                    token = localStorage.getItem("mlToken");

                    axios.get(
                        url,
                        {
                            headers: { Authorization: `Bearer ${token}` }
                        }
                    )
                        .then((response) => {
                            this.setState({
                                loadedOrg: true,
                            });
                            //console.log("stuff", response)
                            const data = response["data"];
                            info.push(
                                <tr key={data.id}>
                                    <td>{"Organization: " + data.label}</td>
                                </tr>);

                            return info;
                        },
                            (error) => {
                                //console.log(error);
                            }
                        );
                


            },
                (error) => {
                    //console.log(error);
                }
            );



    }
    getLocs() {
        //Get all Locations
        if (this.state.loadedLocs) {
            return locaionList;
        }
        else {
            let url = `${config.api}buildings/${AdvSch.Building.id}/locations`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    //console.log(response)
                    const data = response["data"];
                    locaionList = []
                    locaionList=data.map((item) => {
                        return  <tr key={item.id}>
                        <td>{item.locationName}</td>
                        <td>{item.level}</td>
                        <td>{item.deviceLabel}</td>
                    </tr>;
                        
                    });
                    this.setState({
                        loadedLocs: true,
                    });
                    return locaionList;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }





    render() {
        if (this.state.loadedProp&&this.state.loadedOrg) {
            Swal.close();
            //console.log(info);
        return (<Container>
            <AdvancedSearchBackButton />
            <h1>
                <Table>
                    <tbody>
                        {info}
                    </tbody>
                </Table>
            </h1>
            <Row>
                <Col>
                    <h2>Locations:</h2>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name:</th>
                                <th>Level:</th>
                                <th>Device:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getLocs()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>)
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }

}
export default withRouter(BuildingResultsView);
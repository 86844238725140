import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './_base.scss';
import App from './containers/App';
import registerServiceWorker from './utilities/registerServiceWorker';
// import './css/bootstrap/docs/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();

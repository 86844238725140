import React from 'react';
import './GraphBlock.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Line } from 'react-chartjs-2';
import { CSVLink, CSVDownload } from "react-csv";
import { Col } from 'react-bootstrap';

const HumidGraphBlock = (props) => {
    const thKeys = props.keys;
    const thDataSet = props.thData;

    let labels = thKeys.map((key) => {
        let parts = key.split("-");
        for (var i=0; i<3; i++) {
            parts[i] = parts[i].replace(/^0+/, '');
        }
        return parts[1] + "/" + parts[2] + "/" + parts[0].substring(2,4);
    });

    let highestHigh = -1000;
    let lowestLow   =  1000;

    //console.log(props.thData);
    let humidDataSet  = [];
    let highestRecordedHumid = 0;
    for(let i=0; i<thKeys.length; i++) {
        let curData = thDataSet[thKeys[i]];
        let avgTemp = 0;
        let len = 0
        for (let k=0; k<curData.length; k++) {
            let curHumidity = curData[k].humidity
            avgTemp += curHumidity;

            if(curHumidity == null || curHumidity === undefined) continue;

            len += 1
            if (curHumidity > highestRecordedHumid) {
                highestRecordedHumid = curHumidity;
                highestHigh = curHumidity;
            }

            if (curHumidity < lowestLow){
                lowestLow = curHumidity;
            }
        }
        avgTemp = Math.round(avgTemp * 10 / len)/10;
        humidDataSet.push(avgTemp);
    }


    let minHumidityDataSet = new Array()
    let maxHumidityDataSet = new Array()
    for(let i=0; i<thKeys.length; i++) {
        let lowestRecordedHumidity = 1000;
        let highestRecordedHumidity = -1000;

        let curData = thDataSet[thKeys[i]];
        
        for (let k=0; k<curData.length; k++) {
            let curHumidity = curData[k].humidity
            if (curHumidity == null || curHumidity === undefined) continue;
            if (curHumidity > highestRecordedHumidity) {
                highestRecordedHumidity = curHumidity;
            }
            console.log("HUMIDITY")
            console.log(curHumidity)
            console.log("LOWEST CUR HUMIDITY")
            console.log(lowestRecordedHumidity)
            if (curHumidity < lowestRecordedHumidity) {
                console.log("ASSIGNING LOWEST HUMIDITY")
                lowestRecordedHumidity = curHumidity;
            }
            if (curHumidity > highestRecordedHumidity) {
                console.log("ASSIGNING HIGHEST HUMIDITY")
                highestRecordedHumidity = curHumidity;
            }
        }
        minHumidityDataSet.push(lowestRecordedHumidity)
        maxHumidityDataSet.push(highestRecordedHumidity)
      
    }



    //console.log(humidDataSet);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Min Humidity',
                data: minHumidityDataSet,
                borderColor: 'rgba(0, 255, 0, 1)',  // color of your choice
                fill: false,
                borderDash: [5, 5]  // creates dashed line
            },
            {
                label: 'Max Humidity',
                data: maxHumidityDataSet,
                borderColor: 'rgba(255, 0, 0, 1)',  // color of your choice
                fill: false,
                borderDash: [5, 5]  // creates dashed line
            },
            {
                label: 'Humidity',
                data: humidDataSet,
                type: 'line',
                backgroundColor: [
                    'rgba(53, 121, 189, 0.5)'
                ],
                borderColor: [
                    'rgba(53, 121, 189, 1))'
                ],
                borderWidth: 2,
                pointBackgroundColor: 'rgba(53, 121, 189, 1)',
                fill: true
            }
        ]
    };

    const options =  {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    fontColor: "#212529",
                    userCallback: function(value, index, values) {
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);
                        value = value.join(',');
                        value = value + "%";
                        return value;
                    },
                    max: Math.round(highestHigh) + 5,
                    min: Math.round(lowestLow) - 5
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#212529"
                }
            }]
        },
        maintainAspectRatio: false
    };

    const openWaterUse = () => {
        const history = props.history;
        history.push("/water-use");
    }

    // const exportData = () => {
    //     let csvData = [
    //         ["Used", "Wasted"],
    //       ];

    //     gallonData.forEach((gallon, index) => {
    //         csvData.push([gallon, wastedData[index]]);
    //     });
    // }

    return (
        <Container className='dashboardGraphBackground' onClick={props.showModal}>
            <Row style={{'height': '10%'}} >
                <Col xs={12}>
                    <p className="blocksLabel">Humidity</p>
                </Col>
            </Row>
            <Row className="waterUseGraph" >
                {/* <Col xs={12}> */}
                    <Line data={data} options={options} />
                {/* </Col> */}
                {/* <CSVLink data={csvData}>Download me</CSVLink>; */}
            </Row>
        </Container>
    );
    
};

export default HumidGraphBlock;
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { withRouter } from 'react-router-dom';

class TempMaintenance extends Component {

    constructor(props) {
        super(props);
        
    }
    
    componentDidMount() {
        document.body.style.background = "linear-gradient(60deg, rgba(37, 84, 132, 1) 0%, rgba(0, 172, 193, 1) 100%)"
    }  

    render() {
        return (
            <div className="outerContainer" >
            <Container className="formContainer">
                <Form className="loginForm" onSubmit={this.handleSubmit} id="loginForm">
                    <Image className="loginImage" src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg" fluid />

                    <Form.Group controlId="maintenance">
                        <Form.Label style={{textAlign: 'center'}}>The FlowActive Portal is temporarily down for maintenance and updates. We apologize for the inconvenience.</Form.Label>
                    </Form.Group>
                    <br />
                </Form>
            </Container>

            <div>
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use xlink href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlink href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlink href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlink href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            <div className="waveFill"></div>

            </div>
        );
    }
}

export default TempMaintenance;
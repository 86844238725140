import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import Swal from 'sweetalert2';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import './Sidebar.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import MenuLogo from '../../images/menuLogo.js';
import HomeIcon from '../../images/home.js';
import AlertIcon from '../../images/alerts.js';
import RepairIcon from '../../images/repairs.js';
import DeviceIcon from '../../images/devices.js';
import WaterUseIcon from '../../images/waterUse.js';
import RoomIcon from '../../images/rooms.js';
import LocationIcon from '../../images/locationsIcon.js';
import ToolsIcon from '../../images/toolsIcon.js';
import HelpIcon from '../../images/helpIcon';
import CalculatorIcon from '../../images/calculatorIcon';
import LogoutIcon from '../../images/logout.js';
import ProfileIcon from '../../images/profileIcon.js';
import SettingsIcon from '../../images/settingsIcon.js';
import config from '../../utilities/config.js';
import DatePicker from 'react-datepicker';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import WaterRateTable from '../../containers/ProfileView/WaterRateTable';

const Sidebar = (props) => {

    //Listen for window resize and update state variable
    const [windowWidth, setWindowWidth] = useState(null);
    let resizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
      resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    var logoClass = 'menuLogo';
    var homeColor = '#979aa0';
    var homeClass = 'menu-item item-padding';
    var alertColor = '#979aa0';
    var alertClass = 'menu-item item-padding';
    var repairColor = '#979aa0';
    var repairClass = 'menu-item item-padding';
    var deviceColor = '#979aa0';
    var deviceClass = 'menu-item item-padding';
    var waterUseColor = '#979aa0';
    var waterUseClass = 'menu-item item-padding';
    var roomsColor = '#979aa0';
    var roomsClass = 'menu-item item-padding';
    var toolsColor = '#979aa0';
    var toolsClass = 'menu-item item-padding';
    var helpColor = '#979aa0';
    var helpClass = 'menu-item item-padding';
    var logoutColor = '#979aa0';
    var logoutClass = 'menuLogout';

    const curPage = window.location.pathname;
    switch (curPage) {
        case '/':
            homeColor = '#3578bd';
            homeClass = 'menu-item item-padding selected'
            break;
        case '/alerts':
            alertColor = '#3578bd';
            alertClass = 'menu-item item-padding selected'
            break;
        case '/repairs':
            repairColor = '#3578bd';
            repairClass = 'menu-item item-padding selected'
            break;
        case '/device-view':
            deviceColor = '#3578bd';
            deviceClass = 'menu-item item-padding selected'
            break;
        case '/water-use':
            waterUseColor = '#3578bd';
            waterUseClass = 'menu-item item-padding selected'
            break;
        case '/room-view':
            roomsColor = '#3578bd';
            roomsClass = 'menu-item item-padding selected'
            break;
        case '/tools':
            toolsColor = '#3578bd';
            toolsClass = 'menu-item item-padding selected'
            break;
        case '/help':
            helpColor = '#3578bd';
            helpClass = 'menuHelp selected'
            break;
        case '/profile':
            logoutColor = '#3578bd';
            logoutClass = 'menuLogout selected'
            break;
        default:
            break;
    }

    //Custom Date Range
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [startDate, setStartDate] = useState(null);
    const handleStartDate = (date) => setStartDate(date);
    const [endDate, setEndDate] = useState(null);
    const handleEndDate = (date) => setEndDate(date);
    const [showWaterRate, setShowWaterRate] = useState(false);
    const handleCloseWaterRate = () => setShowWaterRate(false);
    const handleShowWaterRate = () => setShowWaterRate(true);

    const handleCustomDate = () => {
        if (startDate == null) {
            Swal.fire({  
                title: 'Invalid Start Date',
            });
            return;
        }
        if (endDate == null) {
            Swal.fire({  
                title: 'Invalid End Date',
            });
            return;
        }
        if (startDate > endDate) {
            Swal.fire({  
                title: 'Start Date Must Be Earlier Than End Date'
            });
            return;
        }
        const fStartDate = startDate.toISOString().split('T')[0];
        const fEndDate = endDate.toISOString().split('T')[0];
        config.dateRange = 0;
        localStorage.setItem("mlDateRange" , 0);
        config.startDate = fStartDate;
        localStorage.setItem("mlStartDate" , fStartDate);
        config.endDate = fEndDate;
        localStorage.setItem("mlEndDate" , fEndDate);
        window.location.reload();
        return false;
    }

    //Buttons
    let buttons = ( <div></div> );
    if (props.buttonsVisible) {
        //Building Select Options
        const buildings = props.buildings;
        const selectedBuilding = config.selectedBuilding;
        let firstBuildingOption = null;
        let secondBuildingOption = null;
        let otherBuildingOptions = null;
        if(buildings.length > 1){
            let selected = false;
            //console.log("SELECTED BUILDING")
            //console.log(selectedBuilding)
            if(selectedBuilding == 0 || selectedBuilding == 'null'){
                selected = true;
            }
            firstBuildingOption = (<Dropdown.Item eventKey="0" active={selected}>All Buildings</Dropdown.Item>);
            otherBuildingOptions = buildings.map((building,index) => {
                let buildingLabel = building["label"];
                let buildingID = building["id"];
                selected = false;
                if(buildingID == selectedBuilding){
                    selected = true;
                }
                return (
                    <Dropdown.Item eventKey={buildingID} active={selected}>{buildingLabel}</Dropdown.Item>
                );
            });
        }
        else if(buildings.length == 1){
            otherBuildingOptions = buildings.map((building,index) => {
                let buildingLabel = building["label"];
                let buildingID = building["id"];
                
                return (
                    <Dropdown.Item eventKey={buildingID} active={true}>{buildingLabel}</Dropdown.Item>
                );
            })
        }

        //Building Title
        let buildingTitle = "";
        if (selectedBuilding == 0 || selectedBuilding == 'null') {
            buildingTitle = "All Buildings";
        }
        else {
            buildings.forEach((building) => {
                if (building.id == selectedBuilding) {
                    buildingTitle = building.label;
                }
            })
        }
        
        const handleExport = () => props.handleExport(config.api, config.token, buildingTitle);

        //Date Range
        const curRange = config.dateRange;
        let sixChecked = false;
        let thirtyChecked = false;
        let customChecked = false;
        if (curRange == 6) {
            sixChecked = true
        }
        else if (curRange == 29) {
            thirtyChecked = true;
        }
        else {
            customChecked = true;
        }

        //Date Range Strings
        const startArray = config.startDate.split('-');
        const startString = `${startArray[1]}/${startArray[2]}/${startArray[0]}`
        const endArray = config.endDate.split('-');
        const endString = `${endArray[1]}/${endArray[2]}/${endArray[0]}`

        const handleTimeChange = (event) => {
            //console.log(event);
            const newRange = event;

            if (newRange == 10) {
                handleShow();
            }
            else {
                const nStartDateString = moment().subtract(newRange , 'days').format("YYYY-MM-DD");
                const nEndDateString = moment().format("YYYY-MM-DD");
    
                config.dateRange = newRange;
                localStorage.setItem("mlDateRange" , newRange);
                config.startDate = nStartDateString;
                localStorage.setItem("mlStartDate" , nStartDateString);
                config.endDate = nEndDateString;
                localStorage.setItem("mlEndDate" , nEndDateString);
    
                props.generateSummary(newRange);
            }
        }

        const handleTimeChangeOld = (event) => {
            //console.log(event);
            const newRange = event.target.value;
            const nStartDateString = moment().subtract(newRange , 'days').format("YYYY-MM-DD");
            const nEndDateString = moment().format("YYYY-MM-DD");

            config.dateRange = newRange;
            localStorage.setItem("mlDateRange" , newRange);
            config.startDate = nStartDateString;
            localStorage.setItem("mlStartDate" , nStartDateString);
            config.endDate = nEndDateString;
            localStorage.setItem("mlEndDate" , nEndDateString);

            props.generateSummary(newRange);
        }

        const handleBuildingSelect = (event) => {
            const buildingID = event;
            //console.log("ON SELECT BUILDING ID")
            //console.log(buildingID)
            config.selectedBuilding = buildingID;
            localStorage.setItem("mlBuildingID" , buildingID);

            
            props.generateSummary(config.dateRange)

        }

        //Page Title
        const title = props.title;

        //Buttons
        if (props.buttonsVisible) {
            buttons = (
                <div className="timeButtonColumn">
                    <ButtonGroup name="dashboardTimeButtons" className="dashboardTimeButtons flex-wrap" >
                        {/* <Button variant="light" value={6} active={sixChecked} onClick={handleTimeChangeOld} >7 Days</Button>
                        <Button variant="light" value={29} active={thirtyChecked} onClick={handleTimeChangeOld} >30 Days</Button>
                        <Button variant="light" value={10} active={customChecked} onClick={handleShow} >Custom</Button> */}
                        <DropdownButton as={ButtonGroup} id="timeButtons" variant="light" title={`${startString} - ${endString}`} onSelect={handleTimeChange}>
                            <Dropdown.Item variant="light" eventKey={6} active={sixChecked} onClick={handleTimeChange} >7 Days</Dropdown.Item>
                            <Dropdown.Item variant="light" eventKey={29} active={thirtyChecked} onClick={handleTimeChange} >30 Days</Dropdown.Item>
                            <Dropdown.Item variant="light" eventKey={10} active={customChecked} onClick={handleShow} >Custom</Dropdown.Item>
                            {/* <Dropdown.Item eventKey={buildingID} active={selected}>{buildingLabel}</Dropdown.Item> */}
                        </DropdownButton>
                        <DropdownButton as={ButtonGroup} id="buildingChoice" variant="light" title={buildingTitle} onSelect={handleBuildingSelect}>
                            {firstBuildingOption}
                            {secondBuildingOption}
                            {otherBuildingOptions}
                        </DropdownButton>
                        <Button variant="light" className="dashboardTimeButtons" onClick={handleExport}>Export...</Button>
                    </ButtonGroup>
                </div>
            );
        }

    }

    //Masquerading
    const endMasquerade = () => {
        const userID = localStorage.getItem("mlOriginalUser");
        //console.log(userID);
        localStorage.setItem("mlUserID" , userID);
        config.userID = userID;
        localStorage.setItem("mlBuildingID" , 0);
        config.selectedBuilding = 0;
        localStorage.setItem("mlIsMasquerading" , false);
        config.isMasquerading = false;
        //console.log(config);
        window.location.href = "/";
    }

    let endMasqueradeButton = ( <div></div> );
    if (config.isMasquerading) {
        endMasqueradeButton = (
            <div className="endMasqueradeColumn" >
                <Button variant="secondary" onClick={endMasquerade} >End Masquerade</Button>
            </div>
        );
    }

    //Tools View
    let sidebarToolsView = ( <div></div> );
    let minimizedSidebarToolsView = ( <div></div> );
    if ((config.isAdmin == 1 || config.isAdmin == "1") && config.userID != 89  ) {
        sidebarToolsView = (
            <a className={toolsClass}  href="/tools">
                <span className="sidebar-item">
                    <ToolsIcon fill={toolsColor} />
                    <span style={{'paddingLeft': '10px'}}>
                        Tools
                    </span>
                </span>
            </a>
        );

        minimizedSidebarToolsView = (
            <a className="sidebarIcon" href="/tools" data-tip="Tools">
                <ToolsIcon className="sidebarIcon" fill={toolsColor}/>
            </a>
        );
    }

    return (
        <div>

            {/* Sidebar Menu */}
            <Menu disableAutoFocus {...props} >
                <a className={logoClass} href="/">
                    <span>
                        <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg"  />
                    </span>
                </a>

                <a className={homeClass}  href="/">
                    <span className="sidebar-item">
                        <HomeIcon fill={homeColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Dashboard
                        </span>
                    </span>
                </a>

                {/* <a className={waterUseClass} href="/water-use">
                    <span className="sidebar-item">
                        <WaterUseIcon fill={waterUseColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Water Use
                        </span>
                    </span>
                </a> */}

                <a className={alertClass} href="/alerts">
                    <span className="sidebar-item">
                        <AlertIcon fill={alertColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Alerts
                        </span>
                    </span>
                </a>

                <a className={deviceClass} href="/device-view">
                    <span className="sidebar-item">
                        <DeviceIcon fill={deviceColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Devices
                        </span>
                    </span>
                </a>

                <a className={roomsClass} href="/room-view">
                    <span className="sidebar-item">
                        <LocationIcon fill={roomsColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Locations
                        </span>
                    </span>
                </a>

                <a className={repairClass} href="/repairs">
                    <span className="sidebar-item">
                        <RepairIcon fill={repairColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Repairs
                        </span>
                    </span>
                </a>

                {sidebarToolsView}

                <a className={helpClass} href="/help">
                    <span className="sidebar-item">
                        <HelpIcon fill={helpColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Help
                        </span>
                    </span>
                </a>

                <a className={logoutClass} href="/profile">
                    <span className="sidebar-item">
                        <ProfileIcon fill={logoutColor} />
                        <span style={{'paddingLeft': '10px'}}>
                            Profile
                        </span>
                    </span>
                </a>
            </Menu>

            {/* Sidebar Minimized */}
            {windowWidth > 650 &&
                <div className="sidebarMin">
                    <a className="sidebarIcon" href="/" data-tip="Dashboard" >
                        <HomeIcon className="sidebarIcon" fill={homeColor}/>
                    </a>
                    {/* <a className="sidebarIcon" href="/water-use" data-tip="Water Use" >
                        <WaterUseIcon className="sidebarIcon" fill={waterUseColor}/>
                    </a> */}
                    <a className="sidebarIcon" href="/alerts" data-tip="Alerts" >
                        <AlertIcon className="sidebarIcon" fill={alertColor}/>
                    </a>
                    <a className="sidebarIcon" href="/device-view" data-tip="Devices" >
                        <DeviceIcon className="sidebarIcon" fill={deviceColor}/>
                    </a>
                    <a className="sidebarIcon" href="/room-view" data-tip="Locations" >
                        <LocationIcon className="sidebarIcon" fill={roomsColor}/>
                    </a>
                    <a className="sidebarIcon" href="/repairs" data-tip="Repairs" >
                        <RepairIcon className="sidebarIcon" fill={repairColor}/>
                    </a>
                    {minimizedSidebarToolsView}
                    {/* <a  className="logoutButton" href="/profile">
                        <SettingsIcon className="logoutButton" fill={logoutColor}/>
                    </a> */}
                    {/* <a  className="helpButton" href="/help" data-tip="Help" >
                        <HelpIcon className="helpButton" fill={helpColor}/>
                    </a> */}
                    <a  className="logoutButton" href="/profile" data-tip="Profile" >
                        <ProfileIcon className="logoutButton" fill={logoutColor}/>
                    </a>
                    <ReactTooltip
                        effect="solid"
                        className="sidebarTooltip"
                        offset={{top:25, left: 0}}
                        backgroundColor="#fff"
                        borderColor="#BABFC9"
                        arrowColor="#BABFC9"
                    />
                </div>
            }

            {/* Topbar */}
            <div className="dashboardTopBar" >
                {windowWidth > 950 && 
                    <div className="logoColumn">
                        <a className="logoClass" href="/">
                            <span>
                                <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg"  />
                            </span>
                        </a>
                    </div>
                }
                {/* {windowWidth > 650 &&
                    <div>
                        <a  className="helpButton" href="/help" >
                            <HelpIcon className="helpButton" fill={'#3578bd'}/>
                        </a>
                        <a  className="calcButton" href="/calculator" >
                            <CalculatorIcon className="calcButton" fill={'#3578bd'}/>
                        </a>
                        <a  className="waterRateButton" onClick={handleShowWaterRate} >
                            <WaterUseIcon className="waterRateButton" fill={'#3578bd'}/>
                        </a>
                    </div>
                } */}
                <a  className="helpButton" href="/help" >
                    <HelpIcon className="helpButton" fill={'#3578bd'}/>
                </a>
                {config.userID == 89 &&
                    <div>
                        <a  className="calcButton" href="/calculator" >
                            <CalculatorIcon className="calcButton" fill={'#3578bd'}/>
                        </a>
                        <a  className="waterRateButton" onClick={handleShowWaterRate} >
                            <WaterUseIcon className="waterRateButton" fill={'#3578bd'}/>
                        </a>
                    </div>
                }
                {endMasqueradeButton}
                {buttons}
            </div>

            {/* Custom Date Modal */}
            <Modal 
                show={show} 
                onHide={handleClose} 
                style={{opacity:1}} 
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Use Custom Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Start Date */}
                        <Row>
                            <Form.Label>Start Date</Form.Label>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="startDateSlect">
                                <DatePicker onChange={handleStartDate} selected={startDate} />
                            </Form.Group>
                        </Row>
                        {/* End Date */}
                        <Row>
                            <Form.Label>End Date</Form.Label>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="endDateSlect">
                                <DatePicker onChange={handleEndDate} selected={endDate} />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCustomDate}>
                        Use Dates
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Water Rate Modal */}
            <Modal 
                show={showWaterRate} 
                onHide={handleCloseWaterRate} 
                style={{opacity:1}} 
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Demo Water & Sewer Rate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WaterRateTable />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Sidebar;
import React, { Component } from 'react';

class AdvancedSearchBackButton extends Component {
    constructor(props) {
        super(props);
        this.goToNewLink = this.goToNewLink.bind(this);
    }

    // componentWillUnmount(){
    //     this.setState({
    //         // holding wether or not each list is loaded or needs to be reloaded
    //         loadedUsers: false,
    //         loadedOrgs: false,
    //         loadedProps: false,
    //         loadedBuildings: false,
    //         loadedLocs: false,
    //         loadedDevices: false,
    //         //Which field the user is focused on
    //         focus: "",
    //         //wether or not the result page should be shown
    //         resultpage: false
    //     });
    // }

    goToNewLink() {
        window.location.href = '/tools/AdvancedSearch'; // replace with your actual link
    }

    render() {
        return (
            <button 
                onClick={this.goToNewLink} 
                type="button" 
                className="swal2-confirm swal2-styled" 
                aria-label="" 
                style={{
                    display: "inline-block", 
                    borderLeftColor: "rgb(48, 133, 214)", 
                    borderRightColor: "rgb(48, 133, 214)"
                }}
            >
                Back
            </button>
        )
    }
}

export default AdvancedSearchBackButton;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col, Dropdown } from 'react-bootstrap';
import AdvancedSearchBackButton from './AdvancedSearchBackButton';

let deviceInfo = [];

class DeviceResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {deviceInfoLoaded: false};
        if(AdvSch.Device) {
            this.state.device = AdvSch.Device;
        }
    }

    render() {
        // Swal.close();
        this.getDeviceInfo();
        return (<Container>
            <AdvancedSearchBackButton />
            <Table>
                <thead>
                    <tr>
                        <th>Device ID:</th><th>Device Name:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.state.device.id}</td>
                        <td>{this.state.device.name}</td>
                    </tr>
                </tbody>
                {deviceInfo}
            </Table>
        </Container>)
    }

    getDeviceInfo() {
        //Get all buildings and properties, and then search through the properties to find ones with matching device IDs

        if(this.state.deviceInfoLoaded) return;

        let url = `${config.api}devices/getDeviceInfo/${this.state.device.id}`,
        token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        ).then((response) => {
            deviceInfo = (
                <><thead>
                    <tr>
                        <th>Device Location:</th><th>Device Property:</th>
                    </tr>
                </thead><tbody>
                        <tr>
                            <td>{response.data.location}</td>
                            <td>{response.data.property}</td>
                        </tr>
                    </tbody><thead>
                        <tr>
                            <th>Device Building:</th><th>Device Organization:</th>
                        </tr>
                    </thead><tbody>
                        <tr>
                            <td>{response.data.building}</td>
                            <td>{response.data.organization}</td>
                        </tr>
                    </tbody></>
            )
            this.setState({ deviceInfoLoaded: true });
        });
    }
}

export default withRouter(DeviceResultsView);
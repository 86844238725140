import React from 'react';
import './GraphBlock.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Line } from 'react-chartjs-2';
import { CSVLink, CSVDownload } from "react-csv";
import { Col } from 'react-bootstrap';

const TempGraphBlock = (props) => {
    const thKeys = props.keys;
    const thDataSet = props.thData;

    let labels = thKeys.map((key) => {
        let parts = key.split("-");
        for (var i=0; i<3; i++) {
            parts[i] = parts[i].replace(/^0+/, '');
        }
        return parts[1] + "/" + parts[2] + "/" + parts[0].substring(2,4);
    });

    let highestHigh = -1000;
    let lowestLow = 1000;

    //console.log(props.thData);
    let tempDataSet  = [];

    for(let i=0; i<thKeys.length; i++) {
        let curData = thDataSet[thKeys[i]];
        let avgTemp = 0;
        let len = 0;
        for (let k=0; k<curData.length; k++) {
            let curTemp = curData[k].temperature;
            let tCheck = Math.round(((curTemp * 9 / 5 + 32) * 100) / 100) 
            if (curTemp == null || curTemp === undefined) continue;
            if(tCheck > highestHigh){
                highestHigh = tCheck
            }
            if(tCheck < lowestLow){
                lowestLow = tCheck
            }
            len += 1;
            avgTemp += tCheck

        }
        avgTemp = Math.round(avgTemp / len);
        tempDataSet.push(avgTemp);
    }
    //console.log(tempDataSet);
    let minTempDataSet = new Array()
    let maxTempDataSet = new Array()
    for(let i=0; i<thKeys.length; i++) {
        let lowestRecordedTemp = 1000;
        let highestRecordedTemp = -1000;

        let curData = thDataSet[thKeys[i]];
        
        for (let k=0; k<curData.length; k++) {
            let curTemp = curData[k].temperature;
            if (curTemp == null || curTemp === undefined) continue;
            let temp = Math.round(((curTemp * 9 / 5 + 32) * 100) / 100);
            if (temp > highestRecordedTemp) {
                highestRecordedTemp = temp;
            }
            console.log("TEMP")
            console.log(temp)
            console.log("LOWEST CUR TEMP")
            console.log(lowestRecordedTemp)
            if (temp < lowestRecordedTemp) {
                console.log("ASSIGNING LOWEST TEMP")
                lowestRecordedTemp = temp;
            }
            if (temp > highestRecordedTemp) {
                console.log("ASSIGNING HIGHEST TEMP")
                highestRecordedTemp = temp;
            }
        }
        minTempDataSet.push(lowestRecordedTemp)
        maxTempDataSet.push(highestRecordedTemp)
      
    }




    console.log("HIGHEST HIGH")
    console.log(highestHigh)

    console.log("LOWEST LOW")
    console.log(lowestLow)



    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Min Temperature',
                data: minTempDataSet,
                borderColor: 'rgba(0, 255, 0, 1)',  // color of your choice
                fill: false,
                borderDash: [5, 5]  // creates dashed line
            },
            {
                label: 'Max Temperature',
                data: maxTempDataSet,
                borderColor: 'rgba(255, 0, 0, 1)',  // color of your choice
                fill: false,
                borderDash: [5, 5]  // creates dashed line
            },
            {
                label: 'Temperature',
                data: tempDataSet,
                type: 'line',
                backgroundColor: [
                    'rgba(53, 121, 189, 0.5)'
                ],
                borderColor: [
                    'rgba(53, 121, 189, 1))'
                ],
                borderWidth: 2,
                pointBackgroundColor: 'rgba(53, 121, 189, 1)',
                fill: true
            }
        ]
    };

    const options =  {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    fontColor: "#212529",
                    userCallback: function(value, index, values) {
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);
                        value = value.join(',');
                        value = value + " °F";
                        return value;
                    },
                    max: highestHigh + 5,
                    min: lowestLow -5

                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#212529"
                }
            }]
        },
        maintainAspectRatio: false
    };

    const openWaterUse = () => {
        const history = props.history;
        history.push("/water-use");
    }

    // const exportData = () => {
    //     let csvData = [
    //         ["Used", "Wasted"],
    //       ];

    //     gallonData.forEach((gallon, index) => {
    //         csvData.push([gallon, wastedData[index]]);
    //     });
    // }

    return (
        <Container className='dashboardGraphBackground' onClick={props.showModal}>
            <Row style={{'height': '10%'}} >
                <Col xs={12}>
                    <p className="blocksLabel">Temperature</p>
                </Col>
            </Row>
            <Row className="waterUseGraph" >
                {/* <Col xs={12}> */}
                    <Line data={data} options={options} />
                {/* </Col> */}
                {/* <CSVLink data={csvData}>Download me</CSVLink>; */}
            </Row>
        </Container>
    );
    
};

export default TempGraphBlock;
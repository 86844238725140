import React, { Component } from 'react';
import { withRouter, BrowserRouter as Route, Switch } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import './ToolsView.scss';
import CardGroup from 'react-bootstrap/CardGroup';
import Menu from '../../components/Menu/Menu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ToolBlock from '../../components/Block/ToolBlock/ToolBlock';
import AddUserView from '../Tools/AddUserView';
import PdfReportView from '../Tools/PdfReportView';
import ToiletFixtureView from '../Tools/ToiletFixtureView';
import FullDeviceReportView from '../Tools/FullDeviceReportView.js';
import AllUsersView from '../Tools/AllUsersView';
import AllDeviceView from '../Tools/AllDeviceView';
import AllLocationsView from '../Tools/AllLocationsView';
import AllPropertiesView from '../Tools/AllPropertiesView.js';
import ModifyPropertyView from '../Tools/ModifyPropertyView.js';
import AddPropertyView from '../Tools/AddPropertyView.js';
import AddLocationView from '../Tools/AddLocationView';
import ModifyLocationView from '../Tools/ModifyLocationView';
import HallSensorTesting from '../Tools/HallSensorTesting';
import ModifyUserView from '../Tools/ModifyUserView';
import ProvisionDevicesView from '../Tools/ProvisionDevicesView';
import ModifyDeviceView from '../Tools/ModifyDeviceView';
import AllBuildingsView from '../Tools/AllBuildingsView';
import AddBuildingView from '../Tools/AddBuildingView';
import ModifyBuildingView from '../Tools/ModifyBuildingView';
import MasqueradeView from '../Tools/MasqueradeView';
import DeviceRawDataView from '../Tools/RawDataView';
import RetireDeviceView from '../Tools/RetireDeviceView';
import ShippingModeView from '../Tools/ShippingModeView.js';
import config from '../../utilities/config.js';
import InstallCheckView from '../Tools/InstallCheckView.js';
import ModelAssignmentView from '../Tools/ModelAssignmentView.js';
import AlertLookupView from '../Tools/AlertLookupView.js';
import BuildingReport from '../Tools/BuildingReportView.js';
import UpdateStatusInterval from '../Tools/UpdateStatusInterval.js';
import DeviceMacAddress from '../Tools/DeviceMacAddress.js';
import FlowTestReport from '../Tools/FlowTestReport.js';
import ButtonModeView from '../Tools/ButtonModeView.js';
import AdvancedSearchView from '../Tools/AdvancedSearchView/AdvancedSearchView.js';
import { gtag } from 'ga-gtag';
class ToolsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTool: null
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
        gtag('event', 'tool_view', {
            poll_title: 'tool_view',
            location: window.location.href
          })
    }


    checkLoggedInAndAdmin = () => {
        if (config.token != undefined && config.token != null && config.isAdmin == 1 && config.userID != 89) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {

        const match = this.props.match;

        if (this.checkLoggedInAndAdmin()) {
            return (
                <Switch>
                    {/* REPORTS */}
                    <Route path={`${match.path}/pdfReport`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <PdfReportView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/fixtureReport`}>
                        <div className="container">
                            <Menu />
                            <br />
                            <ToiletFixtureView />
                        </div>
                    </Route>
                    <Route path={`${match.path}/fullDeviceReport`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <FullDeviceReportView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/installCheck`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <InstallCheckView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/buildingReport`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <BuildingReport />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/alertLookup`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AlertLookupView />
                            </Container>
                        </div>
                    </Route>
                    {/* PRODUCTION */}
                    <Route path={`${match.path}/provision`}>
                        <div className="container">
                            <Menu />
                            <br />
                            <ProvisionDevicesView />
                        </div>
                    </Route>
                    <Route path={`${match.path}/retireDevices`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <RetireDeviceView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/shippingMode`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ShippingModeView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/buttonMode`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ButtonModeView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modelAssignment`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModelAssignmentView />
                            </Container>
                        </div>
                    </Route>
                    {/* DEVICE */}
                    <Route path={`${match.path}/allDevices`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AllDeviceView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/deviceMacAddress`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <DeviceMacAddress />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modifyDevice`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModifyDeviceView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/rawData`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <DeviceRawDataView />
                            </Container>
                        </div>
                    </Route>
                    {/* LOCATION */}
                    <Route path={`${match.path}/allLocations`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AllLocationsView />
                            </Container>
                        </div>
                    </Route>

                    <Route path={`${match.path}/addLocation`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AddLocationView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modifyLocation`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModifyLocationView />
                            </Container>
                        </div>
                    </Route>




                    {/* PROPERTY */}
                    <Route path={`${match.path}/allProperties`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AllPropertiesView />
                            </Container>
                        </div>
                    </Route>

                     <Route path={`${match.path}/addProperty`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AddPropertyView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modifyProperty`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModifyPropertyView />
                            </Container>
                        </div>
                    </Route> 




                    {/* TESTING */}
                    <Route path={`${match.path}/hallSensorTest`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <HallSensorTesting />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/updateStatusMessage`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <UpdateStatusInterval />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/flowTestReport`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <FlowTestReport />
                            </Container>
                        </div>
                    </Route>
                    {/* BUILDING */}
                    <Route path={`${match.path}/allBuildings`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AllBuildingsView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/addBuilding`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AddBuildingView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modifyBuilding`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModifyBuildingView />
                            </Container>
                        </div>
                    </Route>

                    {/* ORGANIZATION */}
                    <Route path={`${match.path}/AdvancedSearch`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AdvancedSearchView />
                            </Container>
                        </div>
                    </Route>



                    {/* USER */}
                    <Route path={`${match.path}/allUsers`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AllUsersView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/addUser`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <AddUserView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/modifyUser`}>
                        <div id="pageOuterContainer" className="pageOuterContainer" >
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                buttonsVisible={false}
                            />
                            <Container id="pageWrap" className="pageWrap" >
                                <ModifyUserView />
                            </Container>
                        </div>
                    </Route>
                    <Route path={`${match.path}/masquerade`}>
                        <MasqueradeView />
                    </Route>
                    <Route path={match.path}>
                        <div id="toolsOuterContainer" className="toolsOuterContainer" >
                            <Sidebar
                                pageWrapId={"toolsPageWrap"}
                                outerContainerId={"toolsOuterContainer"}
                                buttonsVisible={false}
                            />

                            <div id="toolsPageWrap" className="toolsPageWrap" >
                                <Row className="tools-row">
                                    <CardGroup>
                                        <ToolBlock title="Reports" />
                                        <ToolBlock title="Production" />
                                        <ToolBlock title="Testing" />
                                    </CardGroup>
                                </Row>
                                <Row className="tools-row">
                                    <CardGroup>
                                        <ToolBlock title="Device" />
                                        <ToolBlock title="User" />
                                        <ToolBlock title="Organization" />
                                    </CardGroup>
                                </Row>
                                <Row className="tools-row">
                                    <CardGroup>
                                        <ToolBlock title="Properties" />
                                        <ToolBlock title="Building" />
                                        <ToolBlock title="Location" />
                                    </CardGroup>
                                </Row>
                            </div>
                        </div>
                    </Route>
                </Switch>
            );
        }
        else {
            window.location.href = "/";
            return;
        }

    }
}

export default withRouter(ToolsView);
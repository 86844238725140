import React from 'react';
import './DeviceBlocks.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { Doughnut } from 'react-chartjs-2';
import config from '../../../utilities/config';
import ReactTooltip from 'react-tooltip';

const deviceBlocks = (props) => {

    //const rooms = props.rooms;
    //let devices = props.devices;
    const buildings = props.buildings;
    let oDevices = props.devices;
    //let uDevices = {};
    let lowBatteryDevices = [];
    let weakSignalDevices = [];
    let noResponseDevices = [];


    let keys = Object.keys(oDevices);
    //console.log(oDevices);
    //console.log(keys)
    //console.log(buildings)
    for(let i =0 ; i < keys.length ; i++){
        let tmp = oDevices[keys[i]];
        let deviceID = tmp["deviceID"];
        let batteryV = tmp["batteryV"];
        let locationLabel = tmp["locationLabel"];
        let rssi = tmp["rssi"];
        let deliveredTime = tmp["deliveredTime"];
        let issueO = {};

        let signalP = 0;

        if(rssi != null){
            //batteryP = signalP = 2 * (rssi + 100);
            signalP = 2 * (rssi + 100);
        }

        if(signalP < 5){
            issueO["signal"] = 2;
        }
        else if(signalP < 30){
            issueO["signal"] = 1;
        }
        else{
            issueO["signal"] = 0;
        }
        signalP = Math.min(signalP , 100) + "%";

        let batteryP = 0;
        if(batteryV != null){
            batteryP = parseFloat((batteryV - 3200) / 900.0).toFixed(2) * 100;
        }


        if(batteryP < 10){
            issueO["battery"] = 2;
        }
        else if (batteryP < 30){
            issueO["battery"] = 1 
        }
        else{
            issueO["battery"] = 0;
        }



        if(locationLabel == null){
            locationLabel = "Unassigned";
        }
        // else{
        //     locationLabel = "Room " + locationLabel;
        // }

        let fDate = "No Status";
        if (deliveredTime > 0){
            let diff = Date.now() - deliveredTime;

            if(diff > 172800000){
                issueO["time"] = 1;
            }
            else{
                issueO["time"] = 0;
            }

            let d = new Date(deliveredTime);
            fDate = d.toLocaleString();
        }
        else{
            issueO["time"] = 2;
        }

        //var fDate = new Date(deliveredTime);




        if(batteryP < 0){
            batteryP = 0;
        }
        else if(batteryP > 100){
            batteryP = 100;
        }

        batteryP = Math.trunc(batteryP) + "%";


        let buildingLabel = "";
        if (config.selectedBuilding == 0) {
            buildings.forEach((building) => {
                if (building.id == tmp["buildingID"]) {
                    buildingLabel = building.label;
                }
            })
        }
        else {
            buildings.forEach((building) => {
                if (building.id == config.selectedBuilding) {
                    buildingLabel = building.label;
                }
            })
        }

        let finalO = {
            "deviceID" : deviceID,
            "lastResponse" : fDate,
            "battery": batteryP,
            "signalStrength": signalP,
            "locationLabel" : locationLabel,
            "building": buildingLabel,
            "issueO" : issueO
        }

        if (issueO["battery"] > 0) {
            lowBatteryDevices.push(finalO);
        }
        if (issueO["signal"] > 0 && issueO["battery"] == 0) {
            weakSignalDevices.push(finalO);
        }
        if (fDate == "No Status" && issueO["battery"] == 0) {
            noResponseDevices.push(finalO);
        }
        else if (issueO["time"] > 0 && issueO["battery"] == 0) {
            noResponseDevices.push(finalO);
        }
    }


    //Low Battery
    lowBatteryDevices.sort((a, b) => (a.locationLabel > b.locationLabel) ? 1 : -1)
    var lowBatteryExportable = [["Building", "Location", "Battery", "Device"]];
    const lowBatteryRows = lowBatteryDevices.map((device,index) => {
        lowBatteryExportable.push([device.building, device.locationLabel, device.battery, device.deviceID]);
        return (
            <tr>
                <td>{device.building}</td>
                <td>{device.locationLabel}</td>
                <td>{device.battery}</td>
                <td>{device.deviceID}</td>
            </tr>
        );
    })
    const lowBatteryData = {
        datasets: [
          {
            label: "First dataset",
            data: config.selectedBuilding == 29 ? [3,353] : [lowBatteryDevices.length, (keys.length - lowBatteryDevices.length)],
            // data: [lowBatteryDevices.length, (keys.length - lowBatteryDevices.length)],
            fill: true,
            backgroundColor: ["rgba(186, 191, 201, 0.5)", "rgba(86, 185, 71, 0.5)"],
            borderColor: ["rgba(186, 191, 201, 1)", "rgba(86, 185, 71, 1)"]
          }
        ],
        labels: [
            'Issues', 'Working'
        ]
    };

    //Weak Signal
    weakSignalDevices.sort((a, b) => (a.locationLabel > b.locationLabel) ? 1 : -1)
    var weakSignalExportable = [["Building", "Location", "Signal Strength", "Device"]];
    const weakSignalRows = weakSignalDevices.map((device,index) => {
        weakSignalExportable.push([device.building, device.locationLabel, device.signalStrength, device.deviceID]);
        return (
            <tr>
                <td>{device.building}</td>
                <td>{device.locationLabel}</td>
                <td>{device.signalStrength}</td>
                <td>{device.deviceID}</td>
            </tr>
        );
    })
    const weakSignalData = {
        datasets: [
          {
            label: "First dataset",
            data: config.selectedBuilding == 29 ? [2,354] : [weakSignalDevices.length, (keys.length - weakSignalDevices.length)],
            // data: [weakSignalDevices.length, (keys.length - weakSignalDevices.length)],
            fill: true,
            backgroundColor: ["rgba(186, 191, 201, 0.5)", "rgba(86, 185, 71, 0.5)"],
            borderColor: ["rgba(186, 191, 201, 1)", "rgba(86, 185, 71, 1)"]
          }
        ],
        labels: [
            'Issues', 'Working'
        ]
    };

    //No Response
    noResponseDevices.sort((a, b) => (a.locationLabel > b.locationLabel) ? 1 : -1)
    var noResponseExportable = [["Building", "Location", "Last Response", "Device"]];
    const noResponseRows = noResponseDevices.map((device,index) => {
        noResponseExportable.push([device.building, device.locationLabel, device.lastResponse, device.deviceID]);
        return (
            <tr>
                <td>{device.building}</td>
                <td>{device.locationLabel}</td>
                <td>{device.lastResponse}</td>
                <td>{device.deviceID}</td>
            </tr>
        );
    })
    const noResponseData = {
        datasets: [
          {
            label: "First dataset",
            data: [noResponseDevices.length, (keys.length - noResponseDevices.length)],
            fill: true,
            backgroundColor: ["rgba(186, 191, 201, 0.5)", "rgba(86, 185, 71, 0.5)"],
            borderColor: ["rgba(186, 191, 201, 1)", "rgba(86, 185, 71, 1)"]
          }
        ],
        labels: [
            'Issues', 'Working'
        ]
    };

    //Dougnut Chart Options
    const options =  {
        legend: {
            display: false
        },
        maintainAspectRatio: false
    };


    if (props.tableKey == "Battery Life") {
        return (
            <Row className="deviceBlockBackground">
                <Row style={{"height": "10%"}} >
                    <Col xs={10}>
                        <p className="deviceViewLabel">Battery Life</p>
                    </Col>
                    {/* <Col xs={2} style={{"textAlign": "right"}}>
                        <CSVLink className="deviceViewExportBtn" data={lowBatteryExportable} filename={"Low-Battery-Devices.csv"} >Export</CSVLink>
                    </Col> */}
                    <Col xs={2}>
                        <p className="deviceViewLabel" style={{float: 'right', paddingRight: 18}} data-tip="These devices have low or dead batteries. You can order replacememnt devices at www.flowactive.com">&#9432;</p>
                        <ReactTooltip
                            effect="solid"
                            className="sidebarTooltip"
                            offset={{top:25, left: 0}}
                            backgroundColor="#fff"
                            borderColor="#BABFC9"
                            arrowColor="#BABFC9"
                        />
                    </Col>
                </Row>
                <Col md={4}>
                    <div className="doughnutChart">
                        <Doughnut responsive data={lowBatteryData} options={options} />
                    </div>
                </Col>
                <Col md={8} md={8} className="labelCol" >
                    <Table striped responsive bordered size="sm">
                        <thead>
                            <tr>
                                <th>Building</th>
                                <th>Location</th>
                                <th>Battery</th>
                                <th>Device</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {lowBatteryRows}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }
    else if (props.tableKey == "Wi-Fi Connection") {
        return (
            <Row className="deviceBlockBackground">
                <Row style={{"height": "10%"}} >
                    <Col xs={10}>
                        <p className="deviceViewLabel">Device Connection</p>
                    </Col>
                    {/* <Col xs={2} style={{"textAlign": "right"}}>
                        <CSVLink className="deviceViewExportBtn" data={weakSignalExportable} filename={"Weak-Signal-Devices.csv"} >Export</CSVLink>
                    </Col> */}
                    <Col xs={2}>
                        <p className="deviceViewLabel" style={{float: 'right', paddingRight: 18}} data-tip="These devices have a weak Wi-Fi signal. Please check our help section for how to boost your Wi-Fi signal.">&#9432;</p>
                        <ReactTooltip
                            effect="solid"
                            className="sidebarTooltip"
                            offset={{top:25, left: 0}}
                            backgroundColor="#fff"
                            borderColor="#BABFC9"
                            arrowColor="#BABFC9"
                        />
                    </Col>
                </Row>
                <Col md={4}>
                    <div className="doughnutChart">
                        <Doughnut responsive data={weakSignalData} options={options} />
                    </div>
                </Col>
                <Col md={8} md={8} className="labelCol" >
                    <Table striped responsive bordered size="sm">
                        <thead>
                            <tr>
                                <th>Building</th>
                                <th>Location</th>
                                <th>Signal Strength</th>
                                <th>Device</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {weakSignalRows}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }
    else if (props.tableKey == "Late Response") {
        return (
            <Row className="deviceBlockBackground">
                <Row style={{"height": "10%"}} >
                    <Col xs={10}>
                        <p className="deviceViewLabel">No Response</p>
                    </Col>
                    {/* <Col xs={2} style={{"textAlign": "right"}}>
                        <CSVLink className="deviceViewExportBtn" data={noResponseExportable} filename={"No-Response-Devices.csv"} >Export</CSVLink>
                    </Col> */}
                    <Col xs={2}>
                        <p className="deviceViewLabel" style={{float: 'right', paddingRight: 18}} data-tip="These devices are no longer communicating with us. Please check our help section for instructions on restarting a device.">&#9432;</p>
                        <ReactTooltip
                            effect="solid"
                            className="sidebarTooltip"
                            offset={{top:25, left: 0}}
                            backgroundColor="#fff"
                            borderColor="#BABFC9"
                            arrowColor="#BABFC9"
                        />
                    </Col>
                </Row>
                <Col md={4}>
                    <div className="doughnutChart">
                        <Doughnut responsive data={noResponseData} options={options} />
                    </div>
                </Col>
                <Col md={8} className="labelCol" >
                    <Table striped responsive bordered size="sm">
                        <thead>
                            <tr>
                                <th>Building</th>
                                <th>Location</th>
                                <th>Last Response</th>
                                <th>Device</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {noResponseRows}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        );
    }
    else {
        return (
            <div></div>
        )
    }

};

export default deviceBlocks;
